<template>
  <div class="overflow-hidden -mt-20">
    <div id="section-hero" class="hero">
      <div
        class="container flex flex-col justify-start items-start max-w-sm md:max-w-3xl pt-32 md:pt-48 md:px-4 lg:max-w-4xl xl:max-w-5xl w-full z-10"
      >
        <h3
          id="section-hero-title"
          class="font-serif text-black text-4xl md:text-6xl xl:text-8xl leading-none tracking-tight-plus sm:max-w-lg md:max-w-xl xl:max-w-3xl whitespace-no-wrap opacity-0"
        >
          Welcome Back!
        </h3>
        <!-- input -->
        <div
          id="section-hero-form"
          class="flex flex-col mt-12 opacity-0 w-full max-w-md"
        >
          <InputText
            v-model="email"
            :type="'email'"
            :placeholder="'Email'"
            :name="'email'"
            :class-name="'input-border'"
            class="mb-3"
            :error-message="'Please enter a valid email'"
            :display-error="displayErrorEmail"
            @onEnter="onSubmit"
          />
          <InputText
            v-model="password"
            :type="'password'"
            :placeholder="'Password'"
            :autocomplete="'new-password'"
            :name="'password'"
            :class-name="'input-border'"
            :error-message="'Please enter your password'"
            :display-error="displayErrorPassword"
            @onEnter="onSubmit"
          />
          <div class="mt-6 flex items-center justify-end">
            <div class="text-sm leading-5">
              <nuxt-link
                :to="{ name: 'reset-password' }"
                href="#"
                class="font-medium text-gray-600 hover:text-gray-700 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Forgot your password?
              </nuxt-link>
            </div>
          </div>
          <div class="mt-6">
            <p
              v-if="errorMessage"
              class="text-xs font-medium text-red-600 mb-4 text-center"
            >
              {{ errorMessage }}
            </p>
            <ButtonLoading
              :text="'Log in'"
              :is-loading="isLoading"
              class="button-md w-full"
              @click="onSubmit"
            />
          </div>
        </div>
        <div id="section-hero-button" class="mt-4">
          <p class="text-sm text-gray-600 font-base">
            Don't have an account ?
            <nuxt-link
              :to="{ name: 'experts-signup' }"
              class="button-link hover-effect font-base text-black"
            >
              Join as an expert !
            </nuxt-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validEmail, animateElementsTimeline } from '~/helpers';
import InputText from '~/components/input/InputText';
import ButtonLoading from '~/components/button/ButtonLoading';

export default {
  middleware: ['guest'],

  components: {
    ButtonLoading,
    InputText
  },

  data() {
    return {
      isLoading: null,
      displayErrorPassword: false,
      displayErrorEmail: false,
      errorMessage: null,
      // data
      email: null,
      password: null
    };
  },

  computed: {
    ...mapGetters({
      user: 'user',
      isUserRoleExpert: 'isUserRoleExpert'
    })
  },

  watch: {
    email(newV, oldV) {
      this.displayErrorEmail = false;
      this.errorMessage = null;
    },
    password(newV, oldV) {
      this.displayErrorPassword = false;
      this.errorMessage = null;
    }
  },

  mounted() {
    if (process.browser) {
      this.initAnimations();
    }
  },

  methods: {
    /**
     * Register every anim and launch them
     */
    initAnimations() {
      animateElementsTimeline('#section-hero', [
        '#section-hero-title',
        '#section-hero-form',
        '#section-hero-button'
      ]);
    },
    async onSubmit() {
      try {
        if (!validEmail(this.email)) {
          this.displayErrorEmail = true;
          return;
        }
        if (!this.password || this.password.length < 6) {
          this.displayErrorPassword = true;
          return;
        }

        this.errorMessage = null;
        this.isLoading = true;

        const data = {
          identifier: this.email?.trim(),
          password: this.password?.trim()
        };
        const res = await this.$auth.loginWith('local', { data });
        // redirect
        this.$nextTick(() => {
          // name: this.isUserRoleExpert ? 'username-edit' : 'profile',
          this.$router.push({
            name: 'me-slug',
            params: {
              slug: 'profile',
              username: res.data?.user?.expert?.username
            }
          });
        });
      } catch (err) {
        this.errorMessage = 'The email/password is not correct.';
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
/* .title {
  @apply font-serif text-black text-6xl leading-none text-center tracking-tighter mt-8 mb-8 mx-auto;
  font-size: 3rem;
  line-height: 2.75rem;
  @screen sm {
    font-size: 4rem;
    line-height: 3.5rem;
  }
  @screen lg {
    font-size: 5.375rem;
    line-height: 4.75rem;
  }
} */

/* .artwork-container {
  @apply absolute -z-10 inset-0 h-screen overflow-hidden;

  .artwork-left {
    @apply absolute w-full;
    top: 0;
    right: 0;
    transform: translate(-50%, 10%);
    opacity: 0.35;
  }
  .artwork-right {
    @apply absolute w-full;
    top: 0;
    right: 0;
    transform: translate(50%, -25%);
    opacity: 0.4;
  }
} */
.hero {
  @apply flex flex-col justify-center items-center relative pb-32;
  background-color: rgba(249, 249, 249, 0.8);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 90%, #ffffff 100%),
    rgba(249, 249, 249, 0.8);

  @screen md {
    @apply pb-32;
  }

  &::before {
    position: absolute;
    top: -20%;
    left: 30%;
    content: '';
    width: 100%;
    height: 80%;
    transform: rotate(35deg);
    opacity: 0.2;
    background-image: url('~assets/images/blur-1.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;

    @screen md {
      bottom: auto;
      left: auto;
      top: -20%;
      right: -15%;
      content: '';
      width: 55%;
      height: 130%;
      transform: rotate(0deg);
    }
  }
}
</style>
