<template>
  <div class="input-wrapper">
    <input
      :type="type"
      :placeholder="placeholder"
      :name="name"
      :value="value"
      :aria-label="placeholder"
      :autocomplete="autocomplete"
      class="input"
      :class="[className, { error: displayError }]"
      @input="($event) => $emit('input', $event.target.value)"
      @keypress.enter="$emit('onEnter')"
    />
    <slot />
    <p
      v-if="errorMessage && displayError"
      class="text-xs font-medium text-red-600 mt-2 pl-3"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'Aa...'
    },
    autocomplete: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    errorMessage: {
      type: String,
      default: null
    },
    displayError: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="postcss" scoped>
.input-wrapper {
  &:focus-within {
    z-index: 10;
  }
}
.error {
  @apply border-red-500;
}
.icon-right {
  @apply fill-current text-gray-500 transition-colors duration-150 ease-in-out;
}
</style>
