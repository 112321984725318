// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules_dev/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules_dev/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/blur-1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".hero[data-v-4099e2d5]{display:flex;flex-direction:column;justify-content:center;align-items:center;position:relative;padding-bottom:8rem;background-color:hsla(0,0%,97.6%,.8);background:linear-gradient(180deg,hsla(0,0%,100%,0) 90%,#fff),hsla(0,0%,97.6%,.8)}@media (min-width:768px){.hero[data-v-4099e2d5]{padding-bottom:8rem}}.hero[data-v-4099e2d5]:before{position:absolute;top:-20%;left:30%;content:\"\";width:100%;height:80%;transform:rotate(35deg);opacity:.2;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;z-index:0}@media (min-width:768px){.hero[data-v-4099e2d5]:before{bottom:auto;left:auto;top:-20%;right:-15%;content:\"\";width:55%;height:130%;transform:rotate(0deg)}}", ""]);
// Exports
module.exports = exports;
