<template>
  <button
    class="relative"
    :aria-label="text"
    :disabled="disabled"
    :class="[type, color, { loading: isLoading, disabled: disabled }]"
    @click="($event) => !isLoading && $emit('click', $event)"
  >
    <span v-if="text" class="textElement">{{ text }}</span>
    <span
      class="spinner loaderElement"
      :class="type === 'button-outline' ? 'black' : ''"
    />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button-plain' // or 'button-outline'
    },
    text: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'red'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="postcss" scoped>
.textElement {
  @apply transition-all duration-150 ease-in transform translate-y-0 opacity-100;
}
.loaderElement {
  @apply absolute inset-0 transition-all duration-150 ease-in transform opacity-0 translate-y-3;
}
.loading {
  .textElement {
    @apply opacity-0 -translate-y-3;
  }
  .loaderElement {
    @apply transform opacity-100 translate-y-0;
  }
}
</style>
